@font-face {
  font-family: "IBMPlexSans";
  src: url("/assets/fonts/IBMPlexSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("/assets/fonts/IBMPlexSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("/assets/fonts/IBMPlexSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-family: "IBMPlexSans";
  --font-size-title-l: 36px;
  --font-size-title-m: 21px;
  --font-size-body-l: 18px;
  --font-size-body-m: 16px;
  --font-size-body-s: 14px;
}