@import "src/style/utils/mediaQueries";
@import "src/style/global/tag";

.CandidaturesSinglePageLayoutMobileNavigation {
  overflow: hidden;
  border-bottom: 1px solid var(--color-background-01);

  &-head {
    display: flex;
    border-bottom: 1px solid var(--color-background-01);
  }

  &-headLeft {
    border-right: 1px solid var(--color-background-01);
  }

  &-logo {
    padding: var(--spacing-05) var(--spacing-05) var(--spacing-03) var(--spacing-05);
    border-bottom: 1px solid var(--color-background-01);
  }

  &-pourcentage {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-05);
  }

  &-headRight {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-titleContainer {
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--spacing-05);
    border-bottom: 1px solid var(--color-background-01);
  }

  &-title {
    height: 17px;
  }

  &-progressBar {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing-05);
  }

  &-progressBarInner {
    overflow: hidden;
    width: 100%;
    height: 8px;
    background-color: var(--color-progressbar-background);
    border-radius: 30px;

    &::after {
      content: "";
      left: 0;
      top: 0;
      display: block;
      width: var(--progress);
      height: 100%;
      background-color: var(--color-progressbar-inner);
      transition: 0.3s all ease-in-out;
    }
  }

  &-etapesScrollContainer {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    gap: var(--spacing-01);
    padding: var(--spacing-04) var(--spacing-04);
    scroll-padding: var(--spacing-04);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-etapesListItem {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    gap: var(--spacing-01);

    &:last-child &Arrow {
      display: none;
    }
  }

  &-button {
    flex: 1 0 auto;
    border-radius: var(--border-radius-01);
    padding: var(--spacing-04) var(--spacing-05);
    background-color: var(--color-breadcrumb-item-bg);
    box-shadow: var(--color-breadcrumb-item-bg) 0 0 0 2px;
    color: var(--color-breadcrumb-item-accent);
    transition: all 0.3s ease-in-out;

    &.is-unlocked {
      box-shadow: var(--color-breadcrumb-unlocked-accent) 0 0 0 2px;
      background-color: var(--color-breadcrumb-unlocked-bg);
      color: var(--color-breadcrumb-unlocked-accent);
    }

    &.is-passed {
      box-shadow: var(--color-breadcrumb-passed-accent) 0 0 0 2px;
      background-color: var(--color-breadcrumb-passed-bg);
      color: var(--color-breadcrumb-passed-accent);
    }

    &.is-current {
      box-shadow: var(--color-breadcrumb-current-bg) 0 0 0 2px;
      background-color: var(--color-breadcrumb-current-bg);
      color: var(--color-breadcrumb-current-accent);
    }
  }

  &-etapesListItemArrow {
    display: flex;
    flex: 1 0 auto;
  }
}