.Logo {
  display: inline-flex;

  &.is-s {
    height: 30px;
  }

  &.is-l {
    height: 80px;
  }

  &.is-m {
    height: 48px;
  }

  img {
    height: 100%;
  }
}