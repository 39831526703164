@import "src/style/utils/mediaQueries";

.FormFieldset {
  border: 2px solid var(--color-background-01);
  border-radius: var(--border-radius-02);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-05);
  padding: var(--spacing-05);

  @include tablet {
    width: 100%;
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-05);
  }
}