@import "src/style/utils/mediaQueries";

.FormInputRadio {
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  input:checked + span {
    &:before {
      border-color: var(--color-inputField-text);
    }
    &:after {
      opacity: 1;
    }
  }

  &-inner {
    cursor: pointer;
    display: block;
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: var(--spacing-03);
    border-radius: var(--border-radius-01);
    box-shadow: var(--color-inputField-accent) inset 0 0 0 1px;
    padding: var(--spacing-04) var(--spacing-04);

    .has-error & {
      box-shadow: var(--color-border-error) inset 0 0 0 1px;
      color: var(--color-text-error);
    }

    &:before {
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      border: 2px solid var(--color-inputField-accent);
      border-radius: 50%;
      transition: var(--easing-time-01) all ease-in-out;
    }

    &:after {
      opacity: 0;
      content: "";
      position: absolute;
      display: block;
      left: 16px;
      height: 8px;
      width: 8px;
      background: var(--color-inputField-text);
      border-radius: 50%;
      transition: var(--easing-time-01) all ease-in-out;
    }
  }

  &-text {
    padding-top: 2px;
    font-family: var(--font-family);
    font-size: var(--font-size-body-m);
    color: var(--color-inputField-text);

    @include tablet {
      font-size: var(--font-size-body-s);
    }
  }
}