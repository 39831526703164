:root {
  --breakpoint-small: 576px;
  --breakpoint-medium: 768px;
  --breakpoint-large: 1200px;

  /*
   *
   * Primitives
   */

  --color-white: #FFFFFF;
  --color-black: #000000;
  /* ------ */
  --color-brand-10: #CCF3EB;
  --color-brand-20: #99E6D8;
  --color-brand-30: #66DAC4;
  --color-brand-40: #33CDB1;
  --color-brand-50: #00C19D;
  --color-brand-60: #009A7E;
  --color-brand-70: #00745E;
  --color-brand-80: #004D3F;
  --color-brand-90: #00271F;
  /* ------ */
  --color-neutral-02: #F2F2F2;
  --color-neutral-05: #E8E8E8;
  --color-neutral-10: #D3D3D3;
  --color-neutral-15: #a7a7a7;
  --color-neutral-20: #BEBEBE;
  --color-neutral-30: #7B7B7B;
  --color-neutral-40: #4F4F4F;
  --color-neutral-50: #232323;
  --color-neutral-60: #1C1C1C;
  --color-neutral-70: #151515;
  --color-neutral-80: #282828;
  --color-neutral-90: #191919;
  /* ------ */
  --color-successscale-10: #D1F0E1;
  --color-successscale-20: #A2E0C3;
  --color-successscale-30: #74D1A6;
  --color-successscale-40: #45C188;
  --color-successscale-50: #17B26A;
  --color-successscale-60: #128E55;
  --color-successscale-70: #0E6B40;
  --color-successscale-80: #09472A;
  --color-successscale-90: #052415;
  /* ------ */
  --color-warningscale-05: #FEF4E6;
  --color-warningscale-10: #FDE9CE;
  --color-warningscale-20: #FCD39D;
  --color-warningscale-30: #FABC6B;
  --color-warningscale-40: #F9A63A;
  --color-warningscale-50: #F79009;
  --color-warningscale-60: #C67307;
  --color-warningscale-70: #945605;
  --color-warningscale-80: #633A04;
  --color-warningscale-90: #311D02;
  /* ------ */
  --color-errorscale-10: #F7D6D6;
  --color-errorscale-20: #EFACAD;
  --color-errorscale-30: #E68383;
  --color-errorscale-40: #DE595A;
  --color-errorscale-50: #D63031;
  --color-errorscale-60: #AB2627;
  --color-errorscale-70: #801D1D;
  --color-errorscale-80: #561314;
  --color-errorscale-90: #2B0A0A;
  /* ------ */
  --spacing-01: 2px;
  --spacing-02: 4px;
  --spacing-03: 8px;
  --spacing-04: 12px;
  --spacing-05: 16px;
  --spacing-06: 24px;
  --spacing-07: 32px;
  --spacing-08: 40px;
  --spacing-09: 56px;
  /* ------ */
  --border-radius-01: 8px;
  --border-radius-02: 16px;
  --border-radius-03: 32px;
  /* ------ */
  --easing-time-01: 250ms;

  /*
   *
   * Sémantiques
   */

  /* Background */
  --color-background-default: var(--color-white);
  --color-background-01: var(--color-neutral-02);

  /* Border */
  --color-border-default: var(--color-neutral-10);
  --color-border-error: var(--color-errorscale-30);

  /* Item */
  --color-button-primary-bg: var(--color-brand-50);
  --color-button-primary-bg_hover: var(--color-brand-60);
  --color-button-primary-accent: var(--color-white);
  --color-button-primary-outline-accent: var(--color-brand-50);
  --color-button-primary-outline-bg_hover: var(--color-brand-10);
  --color-button-secondary-bg: var(--color-white);
  --color-button-secondary-bg_hover: var(--color-neutral-05);
  --color-button-secondary-accent: var(--color-neutral-50);
  --color-button-disabled-text_disabled: var(--color-neutral-20);
  --color-button-disabled-bg_disabled: var(--color-neutral-10);

  /* Field */
  --color-inputField-accent: var(--color-neutral-30);
  --color-inputField-accent_disabled: var(--color-neutral-10);
  --color-inputField-placeholder: var(--color-neutral-10);
  --color-inputField-text: var(--color-neutral-50);
  --color-inputField-text_disabled: var(--color-neutral-05);
  --color-inputField-checkbox: var(--color-brand-50);
  --color-inputField-outline: var(--color-neutral-30);

  /* InputFieldFile */
  --color-inputFieldFile-button-bg_uploaded: var(--color-brand-50);
  --color-inputFieldFile-button-bg_uploaded_hover: var(--color-brand-60);
  --color-inputFieldFile-button-accent_uploaded: var(--color-white);

  /* Text */
  --color-text-brand: var(--color-brand-50);
  --color-text-primary: var(--color-neutral-90);
  --color-text-disabled: var(--color-neutral-05);
  --color-text-error: var(--color-errorscale-50);

  /* Breadcrumb */
  --color-breadcrumb-current-bg: var(--color-brand-50);
  --color-breadcrumb-current-accent: var(--color-white);
  --color-breadcrumb-item-bg: var(--color-neutral-02);
  --color-breadcrumb-item-accent: var(--color-neutral-10);
  --color-breadcrumb-passed-bg: transparent;
  --color-breadcrumb-passed-accent: var(--color-brand-50);
  --color-breadcrumb-unlocked-bg: transparent;
  --color-breadcrumb-unlocked-accent: var(--color-neutral-20);

  /* ProgressBar */
  --color-progressbar-background: var(--color-neutral-02);
  --color-progressbar-inner: var(--color-brand-50);
}