@import "src/style/utils/mediaQueries";

.CandidaturesSinglePageSubmitConfirmation {
  overflow: hidden;
  z-index: 11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  @include tablet {
    padding: var(--spacing-05);
  }

  &-content {
    max-width: 560px;
    width: 100%;
    border-radius: var(--border-radius-02);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-06);
    padding: var(--spacing-06);
    background-color: var(--color-white);
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-04);
  }

  &-footer {
    display: flex;
    align-items: center;
    gap: var(--spacing-03);

    @include tablet {
      flex-direction: column;
    }
  }

  &-button {
    width: 100%;
  }
}