.FormInputDate {
  input {
    line-height: 1;
    height: 44px;

    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }
}
