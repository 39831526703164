.FormInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01);

  &.is-disabled {
    opacity: 0.2;
  }

  &-error {
    display: flex;
    flex-direction: column;
  }

  &-errorMessage {
    color: var(--color-errorscale-50) !important;
  }
}