@import "src/style/utils/mediaQueries";

.CandidaturesSinglePageSavoirEtreProfessionnel {
  @include tablet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &-inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-06);

    @include media(1000px) {
      grid-template-columns: repeat(1, 1fr);
      gap: var(--spacing-05);
    }
  }
}