@import "src/style/utils/mediaQueries";

.CandidaturesSinglePageTuteursForm {
  @include tablet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &-tuteur {
    position: relative;
  }

  &-buttonRemove {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-01);
    color: var(--color-errorscale-50);
    background-color: var(--color-errorscale-10);
    transition: all var(--easing-time-01) ease-in-out;

    &:hover {
      background-color: var(--color-errorscale-20);
    }
  }
}