@import "src/style/utils/mediaQueries";

.FormInputFile {
  &-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus-visible + label {
      box-shadow: var(--color-inputField-accent) inset 0 0 0 2px;
    }

    &.has-error {
      box-shadow: var(--color-border-error) inset 0 0 0 1px;
      color: var(--color-text-error);

      &:focus-visible + label {
        box-shadow: var(--color-border-error) inset 0 0 0 2px;
      }
    }
  }

  &-label {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    height: 45px;
    border-radius: var(--border-radius-01);
    box-shadow: var(--color-inputField-accent) inset 0 0 0 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-04) var(--spacing-05);
    color: var(--color-inputField-text);
    font-family: var(--font-family);
    font-size: var(--font-size-body-m);
    transition: 0.3s all ease-in-out;

    @include tablet {
      height: 40px;
      font-size: var(--font-size-body-s);
    }

    .is-uploaded & {
      box-shadow: var(--color-inputFieldFile-button-bg_uploaded) inset 0 0 0 1px;
      background-color: var(--color-inputFieldFile-button-bg_uploaded);
      color: var(--color-inputFieldFile-button-accent_uploaded);

      &:hover {
        @include tabletMin {
          box-shadow: var(--color-inputFieldFile-button-bg_uploaded_hover) inset 0 0 0 1px;
          background-color: var(--color-inputFieldFile-button-bg_uploaded_hover);
        }
      }
    }
  }

  &-labelInner {
    display: flex;
    align-items: center;
    gap: var(--spacing-04);
  }
}