@import "src/style/utils/mediaQueries";

.CandidaturesInitialisationPage {
  display: flex;
  height: 100%;

  &-sidebar {
    position: sticky;
    top: 0;
    max-width: 35%;
    width: 100%;
    height: 100vh;

    @include media(1150px) {
      max-width: 100%;
      width: 100%;
      height: 100dvh;
      min-height: 0;
    }
  }

  &-form {
    margin: auto;
    max-width: 652px;

    @include media(1150px) {
      display: none;
      height: 100dvh;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &.is-open {
        display: block;
      }
    }
  }
}