@import "src/style/utils/mediaQueries";

.FormInputCheckbox {
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 100%;

    &:focus-visible {
      & + span {
        &:before {
          border-color: var(--color-inputField-text);
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  input:checked + span {
    &:before {
      border-color: var(--color-inputField-text);
    }
    &:after {
      opacity: 1;
    }
  }

  &-inner {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    gap: var(--spacing-03);
    border-radius: var(--border-radius-01);
    border: 1px solid var(--color-inputField-accent);
    padding: var(--spacing-04) var(--spacing-04);
    font-family: var(--font-family);
    font-size: var(--font-size-body-m);
    line-height: 1.4;
    font-weight: 300;
    color: var(--color-inputField-text);

    @include tablet {
      font-size: var(--font-size-body-s);
    }

    &.is-invalid {
      border: 1px solid var(--color-border-error);
    }

    &:before {
      content: "";
      margin-top: 3px;
      display: block;
      height: 16px;
      width: 16px;
      flex: 1 0 auto;
      border: 2px solid var(--color-inputField-accent);
      border-radius: 4px;
      transition: var(--easing-time-01) all ease-in-out;
    }

    &:after {
      opacity: 0;
      content: "";
      margin-top: 3px;
      position: absolute;
      display: block;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      background-image: url("/assets/icons/check.svg");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: center center;
      background-color: var(--color-inputField-checkbox);
      transition: var(--easing-time-01) all ease-in-out;
    }
  }
}