@import "src/style/utils/mediaQueries";

.CandidaturesSinglePage {
  &-notification {
    position: fixed;
    bottom: var(--spacing-08);
    right: var(--spacing-08);
    border-radius: var(--border-radius-01);
    display: flex;
    align-items: center;
    gap: var(--spacing-03);
    padding: var(--spacing-05) var(--spacing-05);
    border: 1px solid var(--color-border-default);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background-color: var(--color-white);

    &:before {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: var(--color-successscale-50);
    }

    @include tablet {
      width: calc(100% - calc(var(--spacing-05) * 2));
      right: auto;
      left: var(--spacing-05);
      bottom: var(--spacing-05);
    }
  }
}