.FormInputHead {
  display: flex;
  flex-direction: column;

  &-required {
    color: var(--color-errorscale-50);
  }

  &-description {
    font-family: var(--font-family);
    font-size: var(--font-size-body-s);
    font-weight: 300;
    line-height: 1.5;
  }
}