@import "src/style/utils/mediaQueries";

.CandidaturesSinglePageParcoursScolaires {
  display: flex;
  flex-direction: column;

  @include tablet {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &-informations {
    margin-bottom: var(--spacing-05);

    @include tablet {
      margin-top: var(--spacing-05);
      margin-bottom: 0;
      padding: 0 var(--spacing-05);
    }
  }

  &-form {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &-parcoursScolaire {
    position: relative;

    &:first-child &ButtonRemove {
      display: none;
    }
  }

  &-messageFirstParcours {
    padding: var(--spacing-04);
    background: var(--color-warningscale-05);
    border-radius: var(--border-radius-01);
  }

  &-parcoursScolaireButtonRemove {
    position: absolute;
    top: 13px;
    right: 13px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-01);
    color: var(--color-errorscale-50);
    background-color: var(--color-errorscale-10);
    transition: all var(--easing-time-01) ease-in-out;

    &:hover {
      background-color: var(--color-errorscale-20);
    }
  }
}