$breakpoint-large: 1200px;
$breakpoint-medium: 768px;
$breakpoint-small: 576px;

@mixin tablet {
  @media only screen and (max-width: $breakpoint-medium) {
    @content;
  }
}

@mixin tabletMin {
  @media (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $breakpoint-small) {
    @content;
  }
}

@mixin media($max-width) {
  @media (max-width: $max-width) {
    @content;
  }
}