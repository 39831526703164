.tag-h1 {
  font-family: var(--font-family);
  font-size: var(--font-size-title-l);
  font-weight: 400;
  line-height: normal;
  color: var(--color-text-primary);

  @include tablet {
    font-size: var(--font-size-title-m);
  }
}

.tag-h2 {
  font-family: var(--font-family);
  font-size: var(--font-size-title-m);
  font-weight: 400;
  line-height: normal;
  color: var(--color-text-primary);

  &.is-bold {
    font-weight: 500;
  }
}

.tag-p {
  font-family: var(--font-family);
  font-weight: 300;
  line-height: 1.4;
  color: var(--color-text-primary);

  &.is-bold {
    font-weight: 500;
  }

  &.is-l {
    font-size: var(--font-size-body-l);
  }

  &.is-m {
    font-size: var(--font-size-body-m);

    @include tablet {
      font-size: var(--font-size-body-s);
    }
  }

  &.is-s {
    font-size: var(--font-size-body-s);
  }
}

.tag-a {
  color: var(--color-brand-50);
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: var(--easing-time-01) all ease-in-out;

  @include tabletMin {
    &:hover {
      color: var(--color-brand-70);
    }
  }
}

.tag-button {
  display: inline-flex;
  align-self: flex-start;
  padding: var(--spacing-04) var(--spacing-05);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--border-radius-01);
  background-color: var(--color-button-primary-bg);
  color: var(--color-button-primary-accent);
  font-family: var(--font-family);
  font-size: var(--font-size-body-m);
  font-weight: 500;
  line-height: 24px;
  transition: var(--easing-time-01) ease-in-out;

  @include tablet {
    font-size: var(--font-size-body-s);
  }

  @include tabletMin {
    &:hover {
      background-color: var(--color-button-primary-bg_hover);
    }
  }

  &.has-only-icon {
    padding: var(--spacing-04);
  }

  &.is-secondary {
    background-color: var(--color-button-secondary-bg);
    color: var(--color-button-secondary-accent);

    @include tabletMin {
      &:hover {
        background-color: var(--color-button-secondary-bg_hover);
      }
    }
  }

  &.is-outline {
    box-shadow: inset 0px 0px 0px 2px var(--color-button-primary-outline-accent);
    background-color: transparent;
    color: var(--color-button-primary-outline-accent);

    @include tabletMin {
      &:hover {
        background-color: var(--color-button-primary-outline-bg_hover);
      }
    }
  }

  &:disabled {
    box-shadow: inset 0px 0px 0px 2px var(--color-button-disabled-border_disabled);
    background-color: var(--color-button-disabled-bg_disabled);
    color: var(--color-button-disabled-text_disabled);
    cursor: initial;
  }
}

.tag-button-destructive {
  @extend .tag-button;
  background-color: var(--color-button-destructive-bg);
  color: var(--color-button-destructive-accent);

  @include tabletMin {
    &:hover {
      background: var(--color-button-destructive-bg_hover);
    }
  }

  &.is-outline {
    box-shadow: inset 0px 0px 0px 2px var(--color-button-destructive-01-accent);
    background-color: transparent;
    color: var(--color-button-destructive-01-accent);

    @include tabletMin {
      &:hover {
        background-color: var(--color-button-destructive-01-bg_hover);
      }
    }
  }

  &.is-ghost {
    background-color: transparent;
    color: var(--color-button-destructive-01-accent);

    @include tabletMin {
      &:hover {
        background-color: var(--color-button-destructive-01-bg_hover);
      }
    }
  }
}

.tag-legend {
  @extend .tag-p, .is-s, .is-bold;
  float: left;
  width: 100%;
  color: var(--color-inputField-accent);
}

.tag-label {
  @extend .tag-p, .is-m, .is-bold;
  display: flex;
  align-items: center;
  gap: var(--spacing-02);
  color: var(--color-inputField-label);
}

.tag-input {
  @extend .tag-p, .is-m;
  border-radius: var(--border-radius-01);
  box-shadow: var(--color-inputField-accent) inset 0 0 0 1px;
  padding: var(--spacing-04) var(--spacing-05);
  color: var(--color-inputField-text);
  transition: var(--easing-time-01) all ease-in-out;

  &::placeholder {
    color: var(--color-inputField-placeholder);
  }

  &:focus-visible {
    box-shadow: var(--color-inputField-accent) inset 0 0 0 2px;
  }

  &.has-error {
    box-shadow: var(--color-border-error) inset 0 0 0 1px;
    color: var(--color-text-error);

    &:focus-visible {
      box-shadow: var(--color-border-error) inset 0 0 0 2px;
    }
  }
}

.tag-textarea {
  @extend .tag-p, .is-m;
  height: 156px;
  border-radius: var(--border-radius-01);
  box-shadow: var(--color-inputField-accent) inset 0 0 0 1px;
  padding: var(--spacing-04) var(--spacing-05);
  color: var(--color-inputField-text);
  transition: var(--easing-time-01) all ease-in-out;

  &::placeholder {
    color: var(--color-inputField-placeholder);
  }

  &:focus-visible {
    box-shadow: var(--color-inputField-accent) inset 0 0 0 2px;
  }

  &.has-error {
    box-shadow: var(--color-border-error) inset 0 0 0 1px;
    color: var(--color-text-error);

    &:focus-visible {
      box-shadow: var(--color-border-error) inset 0 0 0 2px;
    }
  }
}

.tag-select {
  @extend .tag-p, .is-m;
  width: 100%;
  background-color: transparent;
  background-image: url("/assets/icons/chevron-selector-vertical.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: top 14px right var(--spacing-05);
  border-radius: var(--border-radius-01);
  box-shadow: var(--color-inputField-accent) inset 0 0 0 1px;
  padding: var(--spacing-04) var(--spacing-05);
  color: var(--color-inputField-text);
  transition: var(--easing-time-01) all ease-in-out;

  &:required:invalid {
    color: var(--color-inputField-placeholder);
  }

  &:focus-visible {
    box-shadow: var(--color-inputField-accent) inset 0 0 0 2px;
  }

  &.has-error {
    box-shadow: var(--color-border-error) inset 0 0 0 1px;
    color: var(--color-text-error);

    &:focus-visible {
      box-shadow: var(--color-border-error) inset 0 0 0 2px;
    }
  }
}